import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-light light-blue lighten-4 d-sm-none">
          <a className="navbar-brand" href="/">
            <img
              src="/uploads/voorlichtingsles-logo.png"
              height="40"
              alt="Voorlichtingsles Volwassen Worden"
            />
          </a>
          <button
            className="navbar-toggler toggler-example"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="dark-blue-text">
              <i className="fas fa-bars fa-1x" />
            </span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent1"
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lessen"
                >
                  Lessen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lesmaterialen"
                >
                  Lesmaterialen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/multimedia"
                >
                  Multimedia aanpak
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lesmaterialen/lespakket-gratis-bestellen"
                >
                  Lespakket gratis bestellen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/service"
                >
                  Service
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <header className="bg-blue d-none d-sm-block pt-3">
          <div
            className="container-fluid"
            style={{
              "border-bottom": "rgb(238,38,110) 7px solid"
            }}
          >
            <div className="container">
              <div className="row hideMegaMenu">
                <div className="col-12">
                  <img
                    src="/uploads/voorlichtingsles-logo.png"
                    alt="Voorlichtingsles Volwassen Worden"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <a className="show-menu" href="/" data-value="-1">
                    <i className="fas fa-home fa-2x" />
                  </a>
                  <a className="show-menu" href="/lessen" data-value="0">
                    Lessen
                  </a>
                  <a
                    className="show-menu"
                    href="/lesmaterialen"
                    data-value="1"
                  >
                    Lesmaterialen
                  </a>
                  <a
                    className="show-menu"
                    href="/multimedia"
                    data-value="2"
                  >
                    Multimedia aanpak
                  </a>
                  <a
                    className="show-menu"
                    href="/lesmaterialen/lespakket-gratis-bestellen"
                    data-value="3"
                  >
                    Lespakket gratis bestellen
                  </a>
                  <a className="show-menu" href="/service" data-value="4">
                    Service
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="megamenu container-fluid"
            data-show="0"
            style={{
              "z-index": "99999",
              position: "absolute",
              display: "none"
            }}
          >
            <div id="megamenu_0" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_richtlijnen.jpg"
                      className="img-fluid"
                      alt="Richtlijnen"
                    />
                    <a
                      href="/lessen/richtlijnen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Richtlijnen voor de seksuele voorlichting
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_lesvoorbereiding.jpg"
                      className="img-fluid"
                      alt="Lesvoorbereiding"
                    />
                    <a
                      href="/lessen/lesvoorbereiding"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Lesvoorbereiding
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_tips.jpg"
                      className="img-fluid"
                      alt="Tips"
                    />
                    <a
                      href="/lessen/tips"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Tips voor de seksuele voorlichtingslessen
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_1" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/themagebieden"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Themagebieden
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit"
                            className="d-block mt-1 tab-menu"
                          >
                            Ontwikkeling en puberteit
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/geslachtsorganen"
                            className="d-block mt-1 tab-menu"
                          >
                            Geslachtsorganen
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/cyclus-en-menstruatie"
                            className="d-block mt-1 tab-menu"
                          >
                            Menstruatiecyclus
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/menstruatie-hygine"
                            className="d-block mt-1 tab-menu"
                          >
                            Menstruatie hygiëne
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/tampongebruik"
                            className="d-block mt-1 tab-menu"
                          >
                            Tampongebruik
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/speciale-pakketten"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Speciale pakketten
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes"
                            className="d-block mt-1 tab-menu"
                          >
                            Lesmateriaal voor meisjes
                          </a>
                          <a
                            href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                            className="d-block mt-1 tab-menu"
                          >
                            Lesmateriaal voor jongens
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/speciale-pakketten"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Lespakket gratis bestellen
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding"
                            className="d-block mt-1 tab-menu"
                          >
                            Docentenhandleiding
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_2" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_instructiefilm.jpg"
                      className="img-fluid"
                      alt="Instructiefilm"
                    />
                    <a
                      href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Instructiefilm over seksuele voorlichting «Volwassen
                      worden»
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_kennistest.jpg"
                      className="img-fluid"
                      alt="Online kennistest"
                    />
                    <a
                      href="/multimedia/online-kennistests"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Online kennistest
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_oefeningen.jpg"
                      className="img-fluid"
                      alt="Oefenbladen"
                    />
                    <a
                      href="/multimedia/oefenbladen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Oefenbladen
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_3" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_4_primaironderwijs.jpg"
                      className="img-fluid"
                      alt="Bestel nu gratis"
                    />
                    <a
                      href="/lesmaterialen/lespakket-gratis-bestellen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Bestel nu gratis
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_4" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_belangrijke_vragen.jpg"
                      className="img-fluid"
                      alt="Belangrijke vragen"
                    />
                    <a
                      href="/service/veel-gestelde-vragen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Belangrijke vragen over menstruatie en menstruatiehygiëne
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_belangrijke_links.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                    <a
                      href="/service/belangrijke-links"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Belangrijke links
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_contact.jpg"
                      className="img-fluid"
                      alt="Contact"
                    />
                    <a
                      href="/service/contact"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
          </div>
        </header>
        <main className="hideMegaMenu">
          <div className="container-fluid opening mb-3">
            <div className="container bg-blauw">
              <div className="row">
                <div className="col-md-6 px-4 py-3">
                  <h1 className="h1-responsive text-white">
                    Lesmaterialen voor seksuele voorlichtingslessen
                  </h1>
                </div>
                <div className="col-md-6">
                  <img
                    src="/uploads/img_4456_2_lesmateriaal.jpg"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid hideMegaMenu">
            <div className="container">
              <div id="breadcrumb">
                <div className="bc my-3 d-none d-sm-block ">
                  <a href="/">Home</a> &gt;{" "}
                  <a href="/lesmaterialen">
                    Lesmaterialen voor seksuele voorlichtingslessen
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div id="content">
                    <h1 className="h1-responsive my-3  ">
                      Lesmaterialen voor seksuele voorlichtingslessen
                    </h1>
                    <p className="lead ">
                      Het gratis lesmateriaal over seksuele voorlichting dat
                      hier wordt aangeboden, is bedoeld om leerkrachten en
                      leerkrachten in opleiding te ondersteunen bij het
                      informatief, leerzaam en gevarieerd geven van hun
                      educatieve lessen.
                    </p>
                    <p />
                    <p>
                      Om elke les afzonderlijk te kunnen opbouwen, zijn de
                      materialen op onderwerp gesorteerd. Ze kunnen afzonderlijk
                      of volgens hoofdstukken worden gedownload. De materialen
                      omvatten ook lesmateriaal dat speciaal is samengesteld
                      voor het lesgeven in groep 8 van het primair onderwijs. Er
                      zijn ook speciale pakketten die elk gericht zijn op de
                      ontwikkeling van meisjes en jongens.
                    </p>
                    <p>
                      Het lesmateriaal is ontworpen voor alle soorten scholen op
                      basis van de lesinhoud van groep 8 van de basisschool tot
                      de eerste twee klassen van het voortgezet onderwijs. Het
                      dient als aanvulling op het bestaande lesmateriaal en kan
                      bijzonder goed worden geïntegreerd in de biologielessen.
                    </p>
                    <p>
                      Voor voortgezet onderwijs en het primair onderwijs is er
                      de mogelijkheid{" "}
                      <a href="/lesmaterialen/lespakket-gratis-bestellen">
                        gratis schoolpakketten te bestellen.
                      </a>
                    </p>
                    <p>
                      Deze lespakketten worden in samenwerking met een college
                      (of groep) van experts op het gebied van pedagogen en
                      artsen ontwikkeld en regelmatig geactualiseerd.
                    </p>
                    <p>
                      Van het Gesellschaft fur "Padagogik und Information e.V.
                      (GPI) heeft het schoolprogramma 2018 al voor de vierde
                      keer het Comenius-EduMedia Zegel ontvangen voor
                      pedagogisch, inhoudelijk en creatief hoogwaardige digitale
                      educatieve middelen.
                    </p>
                    <h3>Thema gebieden</h3>
                    <p>
                      Stem uw les af op relevante basisonderwerpen in de
                      seksuele voorlichtingsles. Bedenk een compleet
                      hoofdonderwerp met de leerzame materialen of stel uw
                      individuele lessen samen uit het brede aanbod.
                    </p>
                    <p>
                      <a href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit">
                        Ontwikkeling en puberteit
                      </a>
                      <br />
                      <a href="/lesmaterialen/themagebieden/geslachtsorganen">
                        Geslachtsorganen
                      </a>
                      <br />
                      <a href="/lesmaterialen/themagebieden/cyclus-en-menstruatie">
                        Cyclus en menstruatie
                      </a>
                      <br />
                      <a href="/lesmaterialen/themagebieden/menstruatie-hygine">
                        Menstruatie hygiëne
                      </a>
                      <br />
                      <a href="/lesmaterialen/themagebieden/tampongebruik">
                        Gebruik van tampon
                      </a>
                    </p>
                    <h3>Speciale pakketten</h3>
                    <p>
                      De meeste informatie is voor beide geslachten even
                      belangrijk en interessant. Indien nodig kunnen jongens en
                      meisjes voor sommige vakken ook afzonderlijk worden
                      onderwezen.&nbsp;Gebruik onze speciale lespakketten in een
                      voor{" "}
                      <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens">
                        jongens
                      </a>{" "}
                      en{" "}
                      <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes">
                        meisjes
                      </a>{" "}
                      afzonderlijke veilige omgeving zodat ze meer open kunnen
                      spreken en vragen kunnen stellen.
                    </p>
                    <p />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="bg-blue zij-menu p-3">
                    <a
                      href="/lesmaterialen"
                      className="level-1 d-block text-rood"
                    >
                      Lesmaterialen voor seksuele voorlichtingslessen
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden"
                      className="level-2 d-block "
                    >
                      Themagebieden
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit"
                      className="level-3 d-block "
                    >
                      Ontwikkeling en puberteit
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden/geslachtsorganen"
                      className="level-3 d-block "
                    >
                      Geslachtsorganen
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden/cyclus-en-menstruatie"
                      className="level-3 d-block "
                    >
                      Cyclus en menstruatie
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden/menstruatie-hygine"
                      className="level-3 d-block "
                    >
                      Menstruatie hygiëne
                    </a>
                    <a
                      href="/lesmaterialen/themagebieden/tampongebruik"
                      className="level-3 d-block "
                    >
                      Tampongebruik
                    </a>
                    <a
                      href="/lesmaterialen/speciale-pakketten"
                      className="level-2 d-block "
                    >
                      Speciale pakketten
                    </a>
                    <a
                      href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes"
                      className="level-3 d-block "
                    >
                      Lesmateriaal voor meisjes
                    </a>
                    <a
                      href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                      className="level-3 d-block "
                    >
                      Lesmateriaal voor jongens
                    </a>
                    <a
                      href="/lesmaterialen/lespakket-gratis-bestellen"
                      className="level-2 d-block "
                    >
                      Lespakket gratis bestellen
                    </a>
                    <a
                      href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding"
                      className="level-3 d-block "
                    >
                      Docentenhandleiding
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="B00544">
          <div className="container-fluid pb-3">
            <div className="container">
              <div className="row mr-md-5 pr-md-5">
                <div className="col-md-4 text-center d-none">
                  <a
                    href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout1_jongen.png"
                      className="img-fluid p-3"
                      alt="Materiaal voor jongens"
                    />
                  </a>
                  <h3>Materiaal voor jongens</h3>
                  <div className="pay-off">
                    Lesmateriaal rondom het thema puberteit bij jongens
                  </div>
                  <a
                    href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu downloaden
                  </a>
                </div>
                <div className="col-md-4 text-center d-none">
                  <a
                    href="/lesmaterialen/speciale-pakketten"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout2_primair_onderwijs.png"
                      className="img-fluid p-3"
                      alt="Materiaal voor basisscholen"
                    />
                  </a>
                  <h3>Materiaal voor basisscholen</h3>
                  <div className="pay-off">
                    Lesmateriaal voor seksuele voorlichtingslessen
                  </div>
                  <a
                    href="/lesmaterialen/speciale-pakketten"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu samenstellen
                  </a>
                </div>
                <div className="col-md-4 text-center ">
                  <a
                    href="/lessen/lesvoorbereiding"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout3_lesvoorbereiding.png"
                      className="img-fluid p-3"
                      alt="Hulp bij de lesvoorbereiding"
                    />
                  </a>
                  <h3>Hulp bij de lesvoorbereiding</h3>
                  <div className="pay-off">
                    Tips en checklists voor seksuele voorlichtingslessen
                  </div>
                  <a
                    href="/lessen/lesvoorbereiding"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu informeren
                  </a>
                </div>
                <div className="col-md-4 text-center d-none">
                  <a href="/lesmaterialen" className="stretched-link">
                    <img
                      src="/uploads/callout5_lesmateriaal.png"
                      className="img-fluid p-3"
                      alt="Download lesmaterialen"
                    />
                  </a>
                  <h3>Download lesmaterialen</h3>
                  <div className="pay-off">
                    Uitgebreid aanbod van onderwerpen voor de seksuele
                    voorlichting
                  </div>
                  <a
                    href="/lesmaterialen"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Naar de materialen
                  </a>
                </div>
                <div className="col-md-4 text-center ">
                  <a href="/multimedia" className="stretched-link">
                    <img
                      src="/uploads/callout6_multimedia.png"
                      className="img-fluid p-3"
                      alt="Multimedia voor seksuele voorlichting"
                    />
                  </a>
                  <h3>Multimedia voor seksuele voorlichting</h3>
                  <div className="pay-off">
                    Instructiefilm en interactieve kennistests voor seksuele
                    voorlichting
                  </div>
                  <a
                    href="/multimedia"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu bekijken
                  </a>
                </div>
                <div className="col-md-4 text-center ">
                  <a
                    href="/lesmaterialen/lespakket-gratis-bestellen"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout4_lespakket_bestellen.png"
                      className="img-fluid p-3"
                      alt="Gratis lespakket"
                    />
                  </a>
                  <h3>Gratis lespakket</h3>
                  <div className="pay-off">
                    Relevante lesmaterialen voor seksuele voorlichting
                  </div>
                  <a
                    href="/lesmaterialen/lespakket-gratis-bestellen"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu bestellen
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container pt-3 hideMegaMenu">
            <div className="text-center">
              <img
                className="pb-5"
                style={{
                  width: "290px"
                }}
                src="/uploads/ob-logo.png"
                alt="o.b. professioneel forum"
              />
            </div>
            <div className="row">
              <div className="col-md-4">
                <a className="main" href="/">
                  Homepage
                </a>
                <a href="/service/sitemap">Sitemap</a>
                <a href="/service/contact">Contact</a>
                <a href="/service/juridische-kennisgeving">
                  Juridische kennisgeving
                </a>
                <a href="/service/privacybeleid">Privacy statement</a>
                <a href="/service/cookies">Cookie statement</a>
              </div>
              <div className="col-md-4">
                <a
                  className="main"
                  href="/lesmaterialen/lespakket-gratis-bestellen"
                >
                  Lespakket gratis bestellen
                </a>
                <a href="/lesmaterialen/lespakket-gratis-bestellen">
                  Bestel het nu
                </a>                                  
              </div>
              <div className="col-md-4">
                <a className="main" href="/multimedia">
                  Multimedia voor seksuele voorlichting
                </a>
                <a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden">
                  Educatieve film "Opgroeien"
                </a>
                <a href="/multimedia/online-kennistests/quiz-puberteit">
                  Quiz puberteit
                </a>
                <a href="/multimedia/online-kennistests/quiz-menstruatie">
                  Quiz menstruatie
                </a>
                <a href="/multimedia/online-kennistests/quiz-menstruatie-hygiene">
                  Quiz menstruatiehygiëne
                </a>
                <a href="/multimedia/oefenbladen">Oefenbladen</a>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-4">
                <a className="main" href="/lesmaterialen">
                  Lesmaterialen voor seksuele voorlichtingslessen
                </a>
                <a href="/lesmaterialen/themagebieden">Themagebieden</a>
                <a href="/lesmaterialen/speciale-pakketten">
                  Speciale pakketten
                </a>
              </div>
              <div className="col-md-4">
                <a className="main" href="/lessen">
                  Lesgeven
                </a>
                <a href="/lessen/richtlijnen">
                  Richtlijnen voor seksuele voorlichting
                </a>
                <a href="/lessen/lesvoorbereiding">Lesvoorbereiding</a>
                <a href="/lessen/tips">Tips voor seksuele voorlichting</a>
              </div>
              <div className="col-md-4">
                <a className="main" href="/service">
                  Service en veelgestelde vragen
                </a>
                <a href="/service/veel-gestelde-vragen">
                  Veelgestelde vragen
                </a>
                <a href="/service/belangrijke-links">Belangrijke links</a>
                <a href="/service/contact">Contact &amp; feedback</a>
              </div>
            </div>
            <div className="row mt-5 pb-3">
              <div className="col-12 font-smaller">
                <p>© Johnson &amp; Johnson Consumer B.V. 2006 - 2019.</p>
                <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-instellingen</button>
                <p style={{"float": "left"}}>
                  Deze website is gepubliceerd door Johnson &amp; Johnson                               
                  Consumer B.V., die als enige verantwoordelijk is voor de
                  inhoud. De site is bedoeld voor een Nederlands publiek.
                  <br />
                  Voor het laatst bijgewerkt: 14 oktober 2019.
                </p>
              </div>
            </div>
          </div>
        </footer>
        <script type="text/javascript" src="/js/jquery-3.5.1.js" />
        <script type="text/javascript" src="/js/popper.min.js" />
<script type="text/javascript" src="/js/jquery-migrate-3.3.0.js" />
        <script type="text/javascript" src="/js/bootstrap.min.js" />
        <script type="text/javascript" src="/js/mdb.min.js" />
        <div className="hiddendiv common" />
        <script type="text/javascript" src="/system/js/user.js" />
      </div>
    );
  }
}

export default Page;
